<template>
  <div ref="full-video-header" class="full-video-header">
    <div class="hoverOpa wallpaper-controller" style="display: flex; z-index: 99;position:absolute;
        right:30px;top:70px;flex-direction: column;
        align-items: flex-end;text-shadow: 2px 2px 3px #ff000088;">
      <div style="display: flex;">
        <div class="wallpaper-love-icon"  :class="{active: wallpaperFavoriteList.includes(currentWallpaper) }" ks-text="收藏到最爱壁纸" ks-tag="left" >
          <i class="fa fa-heart" aria-hidden="true" @click.stop="addFavoriteWallpaper()"></i>
        </div>
        <div class="wallpaper-love-icon" ks-text="上一张" ks-tag="left" >
          <i class="fa fa-arrow-left" aria-hidden="true" @click.stop="changeWallpaper(-1)"></i>
        </div>
        <div class="wallpaper-love-icon" ks-text="随机刷新壁纸" ks-tag="left" >
          <i class="fa fa-refresh" aria-hidden="true" @click.stop="changeWallpaper(0)"></i>
        </div>
        <div class="wallpaper-love-icon" ks-text="下一张" ks-tag="left" >
          <i class="fa fa-arrow-right" aria-hidden="true" @click.stop="changeWallpaper(1)"></i>
        </div>
        <label ks-text="启用动态壁纸" ks-tag="left" class="switch">
            <input type="checkbox" v-model="showPosterVideo">
            <span class="slider"></span>
        </label>
      </div>
      <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 240px;
      ">
        <div class="wallpaper-love-icon" style="width: 120px;font-size: 18px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;">主题：{{currentWallpaperTheme || "我的最爱"}}</div>
        <div style="width: 120px;font-size: 16px;">
          <div class="wallpaper-love-icon" ks-text="全屏" ks-tag="left" >
            <i class="fa" :class="{'fa-compress':isFullScreen,'fa-expand':!isFullScreen}" aria-hidden="true" @click.stop="toggleFullscreen()"></i>
          </div>
          <div class="wallpaper-love-icon" ks-text="上一页" ks-tag="left" >
            <i class="fa fa-arrow-left" aria-hidden="true" @click.stop="scrollTo('left')"></i>
          </div>
          <div class="wallpaper-love-icon" ks-text="上一页" ks-tag="left" >
            <i class="fa fa-arrow-right" aria-hidden="true" @click.stop="scrollTo('right')"></i>
          </div>
        </div>
      </div>
      <div ref="wallSwitch-tips" class="wallSwitch-tips overflow-x-auto no-scrollbar">
          <div class="wallSwitch-tips-item" :class="{active:currentWallpaperTheme==item.name}"
          :ks-text="item.name" ks-tag="right" 
            :key="item" v-for="item in wallpaperFolder"
            @click.stop="changeWallpaperTheme(item.name)"
            >
            <svg data-v-7e48b1e8="" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" 
            ><path data-v-7e48b1e8="" d="M777.514667 131.669333a53.333333 53.333333 0 0 1 0 75.434667L728.746667 255.829333h49.92A160 160 0 0 1 938.666667 415.872v320a160 160 0 0 1-160 160H245.333333A160 160 0 0 1 85.333333 735.872v-320a160 160 0 0 1 160-160h49.749334L246.4 207.146667a53.333333 53.333333 0 1 1 75.392-75.434667l113.152 113.152c3.370667 3.370667 6.186667 7.04 8.448 10.965333h137.088c2.261333-3.925333 5.12-7.68 8.490667-11.008l113.109333-113.152a53.333333 53.333333 0 0 1 75.434667 0z m1.152 231.253334H245.333333a53.333333 53.333333 0 0 0-53.205333 49.365333l-0.128 4.010667v320c0 28.117333 21.76 51.157333 49.365333 53.162666l3.968 0.170667h533.333334a53.333333 53.333333 0 0 0 53.205333-49.365333l0.128-3.968v-320c0-29.44-23.893333-53.333333-53.333333-53.333334z m-426.666667 106.666666c29.44 0 53.333333 23.893333 53.333333 53.333334v53.333333a53.333333 53.333333 0 1 1-106.666666 0v-53.333333c0-29.44 23.893333-53.333333 53.333333-53.333334z m320 0c29.44 0 53.333333 23.893333 53.333333 53.333334v53.333333a53.333333 53.333333 0 1 1-106.666666 0v-53.333333c0-29.44 23.893333-53.333333 53.333333-53.333334z" fill="#fff"></path></svg>
          </div>
      </div>
      <div ks-text="切换声音" ks-tag="bottom" >
        <div class="wallpaper-love-icon" style="
        width: 240px;line-height: 34px;
        font-size: 20px;
        position: relative;
        white-space: nowrap;overflow: hidden;text-overflow: ellipsis; "
        @click.stop="(isMuted=!isMuted)">
          <div class="text-marquee" 
          style="position: absolute;overflow:visible;right: 0;">
            <i class="fa" :class="{'fa-volume-up':!isMuted,'fa-volume-off':isMuted}" aria-hidden="true"></i>
            {{ currentWallpaper.match(/[^/]+$/)?.[0].split(".")?.[0] }}
          </div>
        </div>
      </div>
      <el-select v-model="selectedFont" placeholder="选择字体" 
        clearable  @change="handleFontChange"
        style="background-color: #F0F0F0 !important;">
        <el-option v-for="font in fonts" :key="font.name" :label="font.name" :value="font.name">
          <span :style="{ fontFamily: font.import }">{{ font.name }}</span>
        </el-option>
      </el-select>
    </div>
    <video ref="video" class="background-image index-full-video" autoplay="autoplay" 
      :muted="isMuted" loop="loop"
      :src="showPosterVideo?currentWallpaper:DEFAULT_WALLPAPER"
      :poster="DEFAULT_WALLPAPER">
    </video>
  </div>
  </template>
  
<script>
import {getAlistContent} from '../../utils/api' 
import  {navigation,isMobile} from '../../utils/tools'
    export default {
      props: {
        showPosterVideo: {
          type: Boolean,
          default:undefined
        }
      },
  
      data() {
        return {
          articles: [],
          wallpaperFolder:[],
          wallpaperList:[],
          currentWallpaper:"",
          wallpaperFavoriteList:[],
          currentWallpaperTheme:"",
          isMuted:true,
          isFullScreen:false,
          selectedFont: localStorage.getItem("fontFamilyName") || "HarmonyOS Sans SC"
        }
      },
  
      computed: {
        fonts() {
          return this.$store.state.fonts;
        },
        DEFAULT_WALLPAPER(){
          if(localStorage.getItem("DEFAULT_WALLPAPER")){
            let DEFAULT_WALLPAPER = JSON.parse(localStorage.getItem("DEFAULT_WALLPAPER"))
            return isMobile()?DEFAULT_WALLPAPER.PE : DEFAULT_WALLPAPER.PC
          } else {
            return this.$store.state.webInfo.backgroundImage
          }
        }
      },
      beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
      },
      watch: {
        "isMuted": {
          immediate: true,
          handler(newValue, oldValue) {
            if (oldValue !== undefined) {
              localStorage.setItem("isMuted", this.isMuted);
            }
          },
        },
        "$store.state.currentWallpaper":{
          immediate: true,
          handler(newValue, oldValue) {
            if (oldValue !== undefined) {
              this.currentWallpaper = newValue;
            }
          },
        },
        "showPosterVideo": {
          immediate: true,
          handler(newValue, oldValue) {
            if (this.showPosterVideo) {
              document.body.classList.add('showPosterVideo');
              document.body.classList.add('no-scrollbar');
            } else {
              document.body.classList.remove('showPosterVideo');
              document.body.classList.remove('no-scrollbar');
            }
            if (oldValue !== undefined) {
              this.global.showPosterVideo = this.showPosterVideo;
              localStorage.setItem("showPosterVideo", this.showPosterVideo);
              this.changeWallpaper(0);
            }
          },
        },
        "currentWallpaperTheme":{
          immediate: true,
          handler(newValue, oldValue) {
            if (oldValue !== undefined) {
              localStorage.setItem("currentWallpaperTheme", this.currentWallpaperTheme);
              this.changeWallpaperTheme(newValue);
            }
          },
        },
      },
  
      created() {
  
      },
  
      mounted() {
        this.wallpaperFolder = JSON.parse(window.localStorage.getItem("alistSource_wallpaperFolder"))
        this.wallpaperFavoriteList = JSON.parse(window.localStorage.getItem("favoriteWallpaper"))|| []
        this.wallpaperList = this.wallpaperFavoriteList;
        this.showPosterVideo = localStorage.getItem("showPosterVideo")=="true"?true:false;
        this.isMuted = localStorage.getItem("isMuted")=="true"?true:false;
        window.addEventListener("keydown", this.handleKeyDown)
        setTimeout(() => {
          this.$nextTick(()=>{
            if(this.$store.state.currentWallpaper){
              this.currentWallpaper = this.$store.state.currentWallpaper
            }else{
              this.changeWallpaper(0)
            }
          })
        }, 1000);
      },
  
      methods: {
        handleFontChange(){
          let fontFamily = this.fonts.find(font => font.name === this.selectedFont);
          localStorage.setItem("fontFamilyName",fontFamily.name);
          const fontUrl = fontFamily?.href;
          const fontLink = document.createElement('link');
          fontLink.setAttribute('rel', 'stylesheet');
          fontLink.setAttribute('href', fontUrl);
          document.head.appendChild(fontLink);
          document.documentElement.style.setProperty('--globalFont', fontFamily?.import);
        },
        handleKeyDown(event) {
          if(!this.showPosterVideo) return;
          [37,38,39,40].indexOf(event.keyCode) > -1 && event.preventDefault();
          if (event.keyCode === 37) {
            this.changeWallpaper(-1);
          } else if (event.keyCode === 39) {
            this.changeWallpaper(1);
          } else if (event.keyCode === 38) {
            navigation("body")
          } else if (event.keyCode === 40) {
            navigation(".page-container-wrap")
          }
        },
        toggleFullscreen() {
          this.isFullScreen = !this.isFullScreen;
          if (!this.isFullScreen) {
            // 退出全屏
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // Internet Explorer and Edge
              document.msExitFullscreen();
            }
          } else {
            // 进入全屏
            var element = document.documentElement;
            if (element.requestFullscreen) {
              element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { // Firefox
              element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
              element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { // Internet Explorer and Edge
              element.msRequestFullscreen();
            }
          }
        },
        scrollTo(direction) {
          const container = this.$refs["wallSwitch-tips"];
          const containerWidth = container.offsetWidth;
          const contentWidth = container.scrollWidth;
          const scrollLeft = container.scrollLeft;
          let targetScrollLeft;
          if (direction === 'left') {
            targetScrollLeft = scrollLeft - containerWidth;
            if (targetScrollLeft <= -containerWidth) {
              targetScrollLeft = contentWidth - containerWidth;
            }
          } else if (direction === 'right') {
            targetScrollLeft = scrollLeft + containerWidth;
            if (targetScrollLeft >= contentWidth) {
              targetScrollLeft = 0;
            }
          }
          if (targetScrollLeft < 0) {
            targetScrollLeft = 0;
          } else if (targetScrollLeft >= contentWidth - containerWidth) {
            targetScrollLeft = contentWidth - containerWidth;
          }
          container.scrollTo({
            left: targetScrollLeft,
            behavior: 'smooth'
          });
      },
      changeWallpaper(index){
        if(index==0 && this.wallpaperList.length > 0 ){
          this.$store.commit("changeCurrentWallpaper",this.wallpaperList[Math.floor(Math.random() * this.wallpaperList?.length)]);
        } else {
          const currentIndex = this.wallpaperList?.indexOf(this.currentWallpaper);
          const newIndex = currentIndex + index;
          if (newIndex >= 0 && newIndex < this.wallpaperList?.length) {
            this.$store.commit("changeCurrentWallpaper",this.wallpaperList[newIndex]);
            // this.$store.state.currentWallpaper = this.wallpaperList[newIndex];
          }
        }
        // this.$refs.video.src = this.showPosterVideo?this.currentWallpaper:""
      },
      changeWallpaperTheme(Folder){
        this.currentWallpaperTheme = Folder;
        getAlistContent({"path": `/wallpaper/${Folder}`,"password": "",
      "page": 1,"per_page": 100,"refresh": false}).then((res) => {
          const urlTemplate = `//alist.roginx.ink/d/wallpaper/${Folder}/\${name}`;
          this.wallpaperList = res.data.content.map(file => {
            const { name } = file;
            const url = urlTemplate.replace('${name}', name);
            return url;
          });
          this.$nextTick(()=>{
            this.changeWallpaper(0);
          })
        })
        .catch((error) => {
          console.log(error);
        });
      },
      addFavoriteWallpaper() {
        let currentWallpaper = this.currentWallpaper;
        let favoriteWallpaperList = JSON.parse(window.localStorage.getItem("favoriteWallpaper")) || [];
        if (favoriteWallpaperList.includes(currentWallpaper)) {
          favoriteWallpaperList = favoriteWallpaperList.filter(wallpaper => wallpaper !== currentWallpaper);
        } else {
          favoriteWallpaperList.push(currentWallpaper);
        }
        this.wallpaperFavoriteList = favoriteWallpaperList;
        window.localStorage.setItem("favoriteWallpaper", JSON.stringify(favoriteWallpaperList));
        console.log(favoriteWallpaperList);
      },
      pageArticles() {
        this.pagination.current = this.pagination.current + 1;
        this.getArticles();
      },
    }
  }
  </script>
  
<style scoped>
.index-full-video{
  width: 100%;
  height: 100%;
  object-position: center center;
  object-fit: cover;
  background-color: #000;
}
.full-video-header{
  user-select: none;
}
.wallSwitch-tips{
  height: 48px;
  width: 240px;
  align-items: center;
  transition: .5;
  font-size: 22px;
  display: flex;
}
.wallpaper-love-icon{
  cursor: pointer;
  display: inline-block;
  height: 34px;
  margin: 0 10px;
  vertical-align: super;
  line-height: 34px;
  font-size: inherit;
  color: #FFF;
  transition: .6s;
}
.wallpaper-love-icon:hover i,.wallpaper-love-icon.active i{
  transition: .6s;
  color:rgb(242, 93, 100);
}
.wallSwitch-tips .wallSwitch-tips-item{
  margin: 10px;
  width: 20px;
  display: inline-flex;
  border-radius: 50%;
  height: 20px;
  color: #000;
  cursor: pointer;
}
.wallSwitch-tips .wallSwitch-tips-item path {
  transition: .5s;
}
.wallSwitch-tips .wallSwitch-tips-item svg:hover path,.wallSwitch-tips-item.active svg path{
  fill: var(--themeBackground) !important;
}
  </style>
  